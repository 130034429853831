import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import Columns from "./Columns";
import { PrimaryButton } from "office-ui-fabric-react";

const ArticleItem = class extends React.Component {
  render() {
    return (
      <div
        className="content"
        style={{
          borderBottom: "1px solid #dadce0",
          padding: "0 0 16px 0"
        }}
      >
        <Columns>
          <div className="column">
            <Link
              to={this.props.post.fields.slug}
              title={this.props.post.frontmatter.title}
            >
              <Img
                alt={this.props.post.frontmatter.title + " Featured Image"}
                title={this.props.post.frontmatter.title}
                style={{ border: "1px solid #dadce0" }}
                sizes={
                  this.props.post.frontmatter.featuredImage.childImageSharp
                    .sizes
                }
              />
            </Link>
          </div>
          <div
            className="column"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h4
              className="subtitle is-5"
              style={{ marginBottom: 4, fontWeight: 400 }}
            >
              <Link
                to={this.props.post.fields.slug}
                title={this.props.post.frontmatter.title}
              >
                {this.props.post.frontmatter.title}
              </Link>
            </h4>
            <p
              style={{
                color: "#757575",
                fontSize: 12,
                lineHeight: "18px",
                marginBottom: 0
              }}
            >
              By {this.props.post.frontmatter.author}
            </p>
            <p
              style={{
                color: "#757575",
                fontSize: 12,
                lineHeight: "18px",
                marginBottom: 16
              }}
            >
              {this.props.post.frontmatter.date}
            </p>
            <p style={{ fontSize: "14px", lineHeight: "20px" }}>
              {this.props.post.frontmatter.description}
            </p>
            <div className="flex-auto" />
            <p>
              <Link to={this.props.post.fields.slug}>
                <PrimaryButton text="Read More" />
              </Link>
            </p>
          </div>
        </Columns>
      </div>
    );
  }
};

export default ArticleItem;
